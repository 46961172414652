<script lang="ts">
export default {
    name: 'RegenerateProjectModal',
    components: { VerticalStack, HorizontalStack, TextInput },
}
</script>
<script setup lang="ts">
import { IProject } from '@inhaus-ai-limited/project-api-sdk'
import { HorizontalStack, Modal, TextInput, VerticalStack } from '@inhaus-ai-limited/vue-components'
import { ref } from 'vue'
import { useOrganizationStore } from '@/stores/organization';

defineExpose({
    show: () => show(),
    close: () => modal.value?.close(),
})
const organizationStore = useOrganizationStore()

const orgName = ref<string>('')
const displayName = ref<string>('')

const modal = ref<typeof Modal>()

async function show() {
    const success =await modal.value?.show()

    if (success) {
        await organizationStore.createOrganization({
            name: orgName.value,
            display_name: displayName.value,
        })

        
        await organizationStore.fetchOrganizations()
    }
}
</script>

<template>
    <Modal title="Create Organisation" ref="modal" okButton="Create" cancelButton="Cancel" class="[&>div]:xl:p-10">
        <VerticalStack class="gap-[20px]">
            <VerticalStack class="gap-[10px]">
                <label class="font-bold"> Name </label>
                <TextInput
                    v-model="orgName"
                    label="Name"
                    placeholder="Enter Organisation Name"
                    class="h-[40px] border-[1px] !border-black p-[10px]"
                />
                <span class="text-red-800 text-[12px]">Name format organisation-name</span>
            </VerticalStack>
            <VerticalStack class="gap-[10px]">
                <label class="font-bold"> Display Name </label>
                <TextInput
                    v-model="displayName"
                    label="Display Name"
                    placeholder="Enter Display Name"
                    class="h-[40px] border-[1px] !border-black p-[10px]"
                />
            </VerticalStack>
        </VerticalStack>
    </Modal>
</template>
