import { Routes } from '../Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const selectedTeam = localStorage.getItem('selectedTeam');
    console.log('selectedTeam', selectedTeam)
    if (selectedTeam) {
        next()
    } else {
        next({
            name: Routes.SuperAdmin,
        })
    }
}