import { defineStore } from 'pinia'
import { ref, unref, type Ref } from 'vue'
import {
    type Auth0Client,
    type User,
    type IdToken,
    type RedirectLoginOptions,
    type LogoutOptions,
    type GetTokenSilentlyOptions,
    createAuth0Client,
} from '@auth0/auth0-spa-js'





export const useAuth0Store = defineStore(
    'auth0',
    () => {
        // State
        const auth0: Ref<Auth0Client | undefined> = ref(undefined)
        const isAuthenticated: Ref<boolean> = ref(false)
        const isLoading: Ref<boolean> = ref(true)

        const user: Ref<User | undefined> = ref(undefined)
        const idToken: Ref<IdToken | undefined> = ref(undefined)
        const error: Ref<unknown | undefined> = ref(undefined)

        // Getters

        // Actions
        const init = async (): Promise<void> => {
            try {
                auth0.value = await createAuth0Client({
                    domain: 'inhausai-dev.uk.auth0.com',
                    clientId: 'x38eVsnGtX3pktfDh7MY9V14bWKRUXZE',
                    authorizationParams: {
                        redirect_uri: `${window.location.origin}/auth/callback`,
                    },
                    cacheLocation: 'localstorage',
                    // domain: import.meta.env.VITE_AUTH0_DOMAIN,
                    // clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
                })

                if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                    await auth0.value.handleRedirectCallback()
                    window.history.replaceState({}, document.title, window.location.pathname)
                }

                isAuthenticated.value = await auth0.value.isAuthenticated()

                user.value = await auth0.value.getUser()
                idToken.value = await auth0.value.getIdTokenClaims()
            } catch (err) {
                error.value = err
            } finally {
                isLoading.value = false
            }
        }

        const loginWithRedirect = async (options?: RedirectLoginOptions): Promise<void> => {
            await auth0.value?.loginWithRedirect(options)
        }

        const logout = async (options?: LogoutOptions): Promise<void> => {
            await auth0.value?.logout(options)
        }

        const getTokenSilently = async (options?: GetTokenSilentlyOptions): Promise<string | undefined> => {
            return await auth0.value?.getTokenSilently(options)
        }

        // Return
        return {
            // State
            isAuthenticated,
            isLoading,
            user,
            idToken,
            error,
            // Actions
            init,
            loginWithRedirect,
            logout,
            getTokenSilently,
        }
    },
    {
        persist: false,
    }
)
