<script lang="ts">
export default {
    name: 'HeaderContent',
}
</script>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { Container, Button, Dropdown, Card, ButtonGroup } from '@inhaus-ai-limited/vue-components'
import router from '@/router'
import HeroIcon from '@/components/HeroIcon.vue'
import InviteModel from '@/components/InviteModel.vue'
import { useRoute } from 'vue-router'
import { Routes } from '@/router/Routes'
import getCurrentRouteName from '@/utils/getRouteName'
import { useOrganizationStore } from '@/stores/organization'
import { useRefStore } from '@/stores/refStore'
import { useAuth0Store } from '@/stores/auth0Store'

const auth0 = useAuth0Store()
const user = computed(() => auth0.user)
const isLoading = computed(() => auth0.isLoading)
const isAuthenticated = computed(() => auth0.isAuthenticated)

const refStore = useRefStore()
const organizationStore = useOrganizationStore()
const route = useRoute()

const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam') || '{}')
const orgInviteModel = ref<typeof InviteModel>()
var teamOptions = ref()

const login = () => auth0.loginWithRedirect()
const logout = () => {
    refStore.reset()
    localStorage.removeItem('selectedTeam')
    auth0.logout()
}

const navigateToOverViewPage = () => {
    localStorage.removeItem('selectedTeam')
    window.location.href = '/'
}

watch(
    () => organizationStore.organizations,
    (value) => {
        teamOptions = organizationStore.organizations.map((organization: { display_name: string; id: string }) => ({
            label: organization.display_name,
            value: organization.id,
        }))
    }
)

onMounted(async () => {
    await organizationStore.fetchOrganizations()
})
</script>

<template>
    <InviteModel ref="orgInviteModel" />
    <Container class="grid grid-cols-3">
        <Container class="flex justify-start">
            <div v-if="isAuthenticated" class="flex items-center gap-[20px]">
                <Button @click="() => navigateToOverViewPage()" :disabled="selectedTeam.id ? false : true" class="!w-[80px]">Select Teams</Button>
                <span class="ml-2 text-gray-400 font-semibold w-[200px]">{{ selectedTeam.display_name }}</span>
            </div>
        </Container>
        <Container class="flex flex-col justify-center mx-auto">
            <Button variant="label" color="secondary" class="flex-auto justify-center items-center" @click="
                router.push({
                    name: Routes.Home,
                    force: true,
                })
                ">
                <img src="@/assets/logo.png" alt="InHaus.ai" class="w-94 h-25 mx-auto" />
            </Button>
        </Container>
        <Container class="flex flex-col justify-center">
            <div v-if="!isAuthenticated" class="grid my-auto gap-[25px] justify-end h-fit">
                <ButtonGroup class="flex my-auto justify-end h-fit space-x-[5px]">
                    <Button class="w-[100px] max-w-[120px] border-white border-[1px]" variant="default" size="md" color="primary" @click="login()">Log-in</Button>
                </ButtonGroup>
            </div>
            <div v-if="isAuthenticated" class="grid my-auto gap-[25px] justify-end h-fit">
                <Dropdown :fixed="true" class="min-w-[500px] [&>.absolute]:top-[50px] text-slate-400">
                    <template #trigger="{ toggle, isVisible }">
                        <Button size="lg" color="secondary" @click="toggle" class="space-x-10 !justify-end flex">
                            <span class="ml-2 text-gray-400 font-semibold ">{{ user?.email }}</span>
                            <span class="ml-2 font-semibold w-[80px] text-end" :class="'text-black'">{{ selectedTeam.id ? getCurrentRouteName() : "Menu" }}</span>
                            <HeroIcon v-if="isVisible" icon="ChevronUpIcon" class="w-20 h-20 rounded-sm inline-block float-right" />
                            <HeroIcon v-else icon="ChevronDownIcon" class="w-20 h-20 rounded-sm inline-block float-right" />
                        </Button>
                    </template>
                    <Card :square="true" :shadow="true" class="max-w-[200px] float-right">
                        <ul class="flex flex-col items pt-10">
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.Projects ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.Projects })">
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Projects</span>
                                </Button>
                            </li>
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.AdminJobs ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.AdminJobs })">
                                    <HeroIcon icon="BriefcaseIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Jobs</span>
                                </Button>
                            </li>
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.AdminDashboard ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.AdminDashboard })">
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Batch</span>
                                </Button>
                            </li>
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.AdminSettings ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.AdminSettings })">
                                    <HeroIcon icon="CogIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Settings</span>
                                </Button>
                            </li>
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.AdminReports ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.AdminReports })">
                                    <HeroIcon icon="DocumentTextIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Reports</span>
                                </Button>
                            </li>
                            <li v-show="selectedTeam.id">
                                <Button textPosition="left" size="lg" color="secondary" :class="route.name == Routes.Credits ? 'text-left text-black' : 'text-left text-gray-400'" @click="router.push({ name: Routes.Credits })">
                                    <HeroIcon icon="DocumentTextIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Credits</span>
                                </Button>
                            </li>
                            <li>
                                <Button textPosition="left" size="lg" color="secondary" class="text-left text-gray-400" @click="logout">
                                    <HeroIcon icon="LogoutIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Logout</span>
                                </Button>
                            </li>
                        </ul>
                    </Card>
                </Dropdown>
            </div>
        </Container>
    </Container>
</template>
