import { useAuthStore } from '@/stores/auth'
import { Routes } from '../Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authStore = useAuthStore()
    console.log("authStore.isAuthenticated",authStore.isAuthenticated,authStore.user)
    if (authStore.isAuthenticated) {
        return next()
    }
    return next()
}
