import { createRouter, createWebHistory } from 'vue-router'
import RouteMultiGuard from 'vue-router-multiguard'
import { Routes } from './Routes'
import { isAuthenticated, log, hasRole, hasTeam } from '@/router/middleware'
import SignInView from '@/views/auth/signin/index'
import RecoverView from '@/views/auth/recover/index'
import ProjectListView from '@/views/shared/projectList/index'
import ArtdirectorProjectView from '@/views/artdirector/project/index'
import ArtdirectorRevisionView from '@/views/artdirector/revision/index'
import ReviewReferenceImageView from '@/views/shared/reviewReferenceImage/index'
import AdminJobsView from '@/views/admin/jobs/index'
import AdminSettingsView from '@/views/admin/settings/index'
import AdminReportView from '@/views/admin/reports/index'
import AdminDashboardView from '@/views/admin/dashboard/index'
import UnauthorizedView from '@/views/auth/unauthorized/index'
import PageNotFoundView from '@/views/pageNotFound/index'
import homeIfAlreadyLoggedIn from './middleware/homeIfAlreadyLoggedIn'
import ReshootVideo from '@/views/shared/ReshootImage/index'
import SuperAdminView from '@/views/superAdmin/index'
import AuthCallbackView from '@/views/auth/callback/index'

import Credits from "@/views/admin/credits/index"
// const refStore = useRefStore()
// const selectTeam = refStore.getRef('selectedTeam')
// const selectedTeam = refStore.ref('selectedTeam', [])
// console.log('selectTeam', selectedTeam)


const router = createRouter({

    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: Routes.Home,
            redirect: { name: Routes.Projects },
            beforeEnter: RouteMultiGuard([log, hasTeam, isAuthenticated, hasRole('superadmin')])
        },
        {
            path: '/auth/callback',
            name: Routes.AuthCallback,
            component: AuthCallbackView,
            beforeEnter: RouteMultiGuard([log,isAuthenticated]),
        },
        {
            path: '/login',
            name: Routes.SignIn,
            component: SignInView,
            beforeEnter: RouteMultiGuard([log, homeIfAlreadyLoggedIn]),
            props: (route) => ({ redirect: route.query.redirect ? String(route.query.redirect) : undefined }),
        },
        // {
        //     path: '/signup',
        //     name: Routes.SignUp,
        //     component: SignUpView,
        //     beforeEnter: RouteMultiGuard([log, homeIfAlreadyLoggedIn]),
        // },
        {
            path: '/recover',
            name: Routes.Recover,
            component: RecoverView,
            beforeEnter: RouteMultiGuard([log, homeIfAlreadyLoggedIn]),
        },
        {
            path: '/photographer',
            name: Routes.Photographer,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/artdirector',
            name: Routes.ArtDirector,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/projects',
            name: Routes.Projects,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/artdirector/review-reference-image/:projectId',
            props: true,
            name: Routes.ArtDirectorReviewReferenceImage,
            component: ReviewReferenceImageView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },

        {
            path: '/artdirector/project/:projectId',
            props: true,
            name: Routes.ArtDirectorProject,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/artdirector/reshoot-video/:projectId',
            props: true,
            name: Routes.ArtDirectorReshootProject,
            component: ReshootVideo,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/photographer/project/:projectId',
            props: true,
            name: Routes.PhotographerProject,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },

        // temporary route
        {
            path: '/artdirector/project/:projectId/:newSubmission',
            props: true,
            name: Routes.ArtDirectorProjectSubmission,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/artdirector/project/:projectId/revision/:revisionId',
            props: true,
            name: Routes.ArtDirectorRevision,
            component: ArtdirectorRevisionView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/admin/jobs',
            name: Routes.AdminJobs,
            component: AdminJobsView,
            beforeEnter: RouteMultiGuard([
                log,
                isAuthenticated,
                hasTeam,
                hasRole('superadmin')
            ]),
        },
        {
            path: '/admin/settings',
            name: Routes.AdminSettings,
            component: AdminSettingsView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        {
            path: '/admin/reports',
            name: Routes.AdminReports,
            component: AdminReportView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },


        // TODO Check why it goes to unauthorised when loggin into qa
        {
            path: '/auth/unauthorized',
            name: Routes.Unauthorized,
            component: UnauthorizedView,
            beforeEnter: RouteMultiGuard([log]),
        },
        {
            path: '/quality-assurance',
            name: Routes.QualityAssurance,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        // {
        //     path: '/quality-assurance/dashboard',
        //     name: Routes.QualityAssuranceDashboard,
        //     component: QualityAssuranceDashboardView,
        //     beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        // },
        {
            path: '/quality-assurance/review-reference-image/:projectId',
            props: true,
            name: Routes.QualityAssuranceReviewReferenceImage,
            component: ReviewReferenceImageView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },
        // {
        //     path: '/quality-assurance/selection/:projectId',
        //     props: true,
        //     name: Routes.QualityAssuranceReviewSelection,
        //     component: QualityAssuranceReviewSelectionView,
        //     beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        // },
        {
            path: '/quality-assurance/reshoot-video/:projectId',
            props: true,
            name: Routes.QualityAssuranceReshootProject,
            component: ReshootVideo,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        },

        {
            path: '/admin/dashboard',
            name: Routes.AdminDashboard,
            component: AdminDashboardView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('superadmin')]),
        },
        {
            path: '/:catchAll(.*)*',
            name: Routes.NotFound,
            component: PageNotFoundView,
            beforeEnter: RouteMultiGuard([log]),
        },
        {
            path: '/super-admin',
            name: Routes.SuperAdmin,
            component: SuperAdminView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('superadmin')]),
        },
        {

            path: '/credits',
            name: Routes.Credits,
            component: Credits,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasTeam, hasRole('superadmin')]),
        }

    ],
})

export default router
export { Routes }
