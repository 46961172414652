// src/stores/organization.ts

import { defineStore } from 'pinia'
import { toRaw } from 'vue'
import { ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

import { useAuth0Store } from './auth0Store'
 
// const auth  = useAuth0Store()

export const useOrganizationStore = defineStore('organization', () => {
    // State: List of organizations
    const organizations: any = ref([])
   
    const { getTokenSilently } = useAuth0Store();
    // State: Loading indicator
    const isLoading = ref(false)

    // Auth0 instance for authentication


    // Fetches organizations from Auth0 API
    const fetchOrganizations = async () => {
        try {
            // Obtain access tokens

            const access_token = await refreshToken()

            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to fetch organizations
            const response = await fetch('https://inhausai-dev.uk.auth0.com/api/v2/organizations', requestOptions)
            const result = await response.json()

            // Map through result and add isEnabled field to each organization
            const updatedOrgs = await Promise.all(result.map(async (org: any) => {
                org.isEnabled = await isOrgConnected(org.id, 'con_6CiVM33ty0QngmTo')
                return org
            }))
            // Optional delay
            await new Promise(resolve => setTimeout(resolve, 1000))

            // Update organizations only after all processing is done
            organizations.value = updatedOrgs

        } catch (error) {
            console.error('Error fetching organizations:', error)
        } finally {
            isLoading.value = false
        }
    }


    // Refreshes the access token using client credentials
    const refreshToken = async (): Promise<string> => {
        const url = `https://inhausai-dev.uk.auth0.com/oauth/token`

        const body = new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: import.meta.env.VITE_AUTH0_EXPLORER_CLIENT_ID,
            client_secret: import.meta.env.VITE_AUTH0_EXPLORER_CLIENT_SECRET,
            audience: import.meta.env.VITE_AUTH0_EXPLORER_AUDIENCE,
            redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT_URI,
        })

        try {
            // API call to refresh token
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body,
            })

            if (!response.ok) {
                throw new Error(`Failed to refresh token: ${response.statusText}`)
            }

            const { access_token } = await response.json()

            return access_token
        } catch (error) {
            console.error('Failed to refresh token:', error)
            throw error
        }
    }

    // Deletes an organization by its ID
    const deleteOrganization = async (organizationId: string) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to delete organization
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to delete organization: ${response.statusText}`)
            }

            // Remove organization from state
            const index = organizations.value.findIndex((org: any) => org.id === organizationId)
            if (index !== -1) {
                organizations.value.splice(index, 1)
            }
            isLoading.value = false
        } catch (error) {
            console.error('Error deleting organization:', error)
            isLoading.value = false
        }
    }

    // Connects to an organization by adding a connection
    const connectToOrganization = async (organizationId: string) => {
        const access_token = await refreshToken()
        console.log('access_token', organizationId)

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    connection_id: 'con_6CiVM33ty0QngmTo',
                    assign_membership_on_login: false,
                }),
                redirect: 'follow',
            }

            // API call to connect to organization
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}/enabled_connections`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to connect to organization: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            // Update connections in state
            const index = organizations.value.findIndex((org: any) => org.id === organizationId)
            if (index !== -1) {
                organizations.value[index].connections = result
            }
        } catch (error) {
            console.error('Error connecting to organization:', error)
            isLoading.value = false
        }
    }

    // Deletes a connection from an organization
    const deleteOrganizationConnection = async (organizationId: string, connectionId: string) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to delete organization connection
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}/enabled_connections/${connectionId}`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to delete organization connection: ${response.statusText}`)
            }

            // Remove connection from state
            const index = organizations.value.findIndex((org: any) => org.id === organizationId)
            if (index !== -1) {
                const connectionIndex = organizations.value[index].connections.findIndex(
                    (conn: any) => conn.id === connectionId
                )
                if (connectionIndex !== -1) {
                    organizations.value[index].connections.splice(connectionIndex, 1)
                }
            }
            isLoading.value = false
        } catch (error) {
            console.error('Error deleting organization connection:', error)
            isLoading.value = false
        }
    }

    // Edits an organization's details
    const editOrganization = async (organizationId: string, organizationData: any) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'PATCH',
                headers: myHeaders,
                body: JSON.stringify(organizationData),
                redirect: 'follow',
            }

            // API call to edit organization
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to edit organization: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            // Update organization in state
            const index = organizations.value.findIndex((org: any) => org.id === organizationId)
            if (index !== -1) {
                organizations.value[index] = result
            }
        } catch (error) {
            console.error('Error editing organization:', error)
            isLoading.value = false
        }
    }

    // Creates a new organization
    const createOrganization = async (organizationData: any) => {
        const access_token = await refreshToken();
     

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(organizationData),
                redirect: 'follow',
            }

            // API call to create organization
            const response = await fetch('https://inhausai-dev.uk.auth0.com/api/v2/organizations', requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to create organization: ${response.statusText}`)
            }

            

            


            const result = await response.json()



            isLoading.value = false

            const settings = await createSettings({displayName:organizationData.name,teamId:result.id});


            organizations.value.push(result)
        } catch (error) {
            console.error('Error creating organization:', error)
            isLoading.value = false
        }
    }

    // Checks if an organization is connected with a specific connection
    const isOrgConnected = async (organizationId: string, connectionId: string): Promise<boolean> => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to check connection
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}/enabled_connections`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to fetch connections for organization: ${response.statusText}`)
            }

            const result = await response.json()

            isLoading.value = false

            return result.some((conn: any) => conn.connection_id === connectionId)
        } catch (error) {
            console.error('Error fetching connections for organization:', error)
            isLoading.value = false
            return false
        }
    }

    // Fetches users associated with the selected organization
    const fetchOrganizationUsers = async () => {
        const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam') || '{}')
        const organizationId = selectedTeam.id

        if (!organizationId) {
            console.error('No organization ID found in selectedTeam')
            return
        }

        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to fetch organization members
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}/members?include_fields=true&fields=user_id,email,picture,name,roles`,
                requestOptions
            )
            if (!response.ok) {
                throw new Error(`Failed to fetch users for organization: ${response.statusText}`)
            }



            const result = await response.json()

            const updated = await Promise.all(result.map(async (user: any) => {
                const temp = await fetchUser(user.user_id)
                user.blocked = temp.blocked ? temp.blocked : false
                return user
            }))


            isLoading.value = false

            return updated
        } catch (error) {
            console.error('Error fetching users for organization:', error)
            isLoading.value = false
            return []
        }
    }

    // Invites a new member to the organization
    const inviteMemberToOrganization = async (memberEmail: string, roleId: string) => {
        const access_token = await refreshToken()

        const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam') || '{}')
        const organization = organizations.value.find((org: any) => org.id === selectedTeam.value)
        const organizationId = organization.id

        if (!organizationId) {
            console.error('No organization ID found in selectedTeam')
            return
        }

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    inviter: {
                        name: organization.name,
                    },
                    invitee: {
                        email: memberEmail,
                    },
                    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
                    connection_id: 'con_6CiVM33ty0QngmTo',
                    app_metadata: {},
                    user_metadata: {},
                    ttl_sec: 0,
                    roles: [roleId],
                    send_invitation_email: true,
                }),
                redirect: 'follow',
            }

            // API call to invite member
            const response = await fetch(
                `https://inhausai-dev.uk.auth0.com/api/v2/organizations/${organizationId}/invitations`,
                requestOptions
            )

            if (!response.ok) {
                throw new Error(`Failed to invite member to organization: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            // Update members in state
            const index = organizations.value.findIndex((org: any) => org.id === organizationId)
            if (index !== -1) {
                organizations.value[index].members = result
            }
        } catch (error) {
            console.error('Error inviting member to organization:', error)
            isLoading.value = false
        }
    }

    // Fetches roles from Auth0
    const fetchRoles = async () => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            // API call to fetch roles
            const response = await fetch('https://inhausai-dev.uk.auth0.com/api/v2/roles', requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to fetch roles: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            return result
        } catch (error) {
            console.error('Error fetching roles:', error)
            isLoading.value = false
            return []
        }
    }

    // Updates a user's information within the organization
    const updateOrganizationUser = async (userId: string, updateData: any) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'PATCH',
                headers: myHeaders,
                body: JSON.stringify(updateData),
                redirect: 'follow',
            }

            // API call to update user
            const response = await fetch(`https://inhausai-dev.uk.auth0.com/api/v2/users/${userId}`, requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to update user: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false
        } catch (error) {
            console.error('Error updating organization user:', error)
            isLoading.value = false
        }
    }

    // Fetches a user by ID
    const fetchUser = async (userId: string) => {
        const access_token = await refreshToken()
        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }

            const response = await fetch(`https://inhausai-dev.uk.auth0.com/api/v2/users/${userId}`, requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to fetch user: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            return result
        } catch (error) {
            console.error('Error fetching user:', error)
            isLoading.value = false
            return null
        }
    }

    const activateUser = async (userId: string) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'PATCH',
                headers: myHeaders,
                body: JSON.stringify({ blocked: false }),
                redirect: 'follow',
            }

            // API call to update user
            const response = await fetch(`https://inhausai-dev.uk.auth0.com/api/v2/users/${userId}`, requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to activate user: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            // Update user in state if necessary
            const userIndex = organizations.value.findIndex((org: any) => org.members.some((user: any) => user.user_id === userId))
            if (userIndex !== -1) {
                const memberIndex = organizations.value[userIndex].members.findIndex((user: any) => user.user_id === userId)
                if (memberIndex !== -1) {
                    organizations.value[userIndex].members[memberIndex].blocked = false
                }
            }
        } catch (error) {
            console.error('Error activating user:', error)
            isLoading.value = false
        }
    }

    const deactivateUser = async (userId: string) => {
        const access_token = await refreshToken()

        try {
            isLoading.value = true
            const myHeaders = new Headers()
            myHeaders.append('Accept', 'application/json')
            myHeaders.append('Authorization', 'Bearer ' + access_token)
            myHeaders.append('Content-Type', 'application/json')

            const requestOptions: RequestInit = {
                method: 'PATCH',
                headers: myHeaders,
                body: JSON.stringify({ blocked: true }),
                redirect: 'follow',
            }

            // API call to update user
            const response = await fetch(`https://inhausai-dev.uk.auth0.com/api/v2/users/${userId}`, requestOptions)
            if (!response.ok) {
                throw new Error(`Failed to deactivate user: ${response.statusText}`)
            }

            const result = await response.json()
            isLoading.value = false

            // Update user in state if necessary
            const userIndex = organizations.value.findIndex((org: any) => org.members.some((user: any) => user.user_id === userId))
            if (userIndex !== -1) {
                const memberIndex = organizations.value[userIndex].members.findIndex((user: any) => user.user_id === userId)
                if (memberIndex !== -1) {
                    organizations.value[userIndex].members[memberIndex].blocked = true
                }
            }
        } catch (error) {
            console.error('Error deactivating user:', error)
            isLoading.value = false
        }
    }

    const createSettings = async (settings: any) => {
        console.log('settings', settings)
        const token = await getTokenSilently({
            authorizationParams: {
                audience: 'https://settings-api.dev.inhausai-infra.services',
            },
        })
        
    
        try {
          
    
            const response = await fetch('https://settings-api.dev.inhausai-infra.services/rest/settings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-team-id': settings.teamId,
                },
                body: JSON.stringify(settings)
            }).catch((err) => {
                // console.log('err', err)
            })
    
            if (!response || !response.ok) {
                throw new Error(`Failed to create settings`)
            }
    
            const responseData = await response.json()
            return responseData
        } catch (error) {
    
        }
    }

    return {
        organizations,
        fetchOrganizations,
        createOrganization,
        editOrganization,
        deleteOrganization,
        connectToOrganization,
        deleteOrganizationConnection,
        isOrgConnected,
        fetchOrganizationUsers,
        inviteMemberToOrganization,
        fetchRoles,
        fetchUser,
        updateOrganizationUser,
        isLoading,
        activateUser,
        deactivateUser,
    }
})


