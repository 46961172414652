import { useRoute } from 'vue-router';

export const ReversedRoutes: { [key: string]: string } = {
  '/': 'Home',
  'signin': 'SignIn',
  'signup': 'SignUp',
  'recover': 'Recover',
  'photographer': 'Photographer',
  'photographer-project': 'Dashboard',
  'artdirector': 'Projects',
  'artdirector-check-reference-image': 'Review',
  'artdirector-project': 'Project',
  'artdirector-project-submission': 'Submission',
  'artdirector-revision': 'Revision',
  'artdirector-reshoot-project': 'Reshoot',
  'quality-assurance': 'Dashboard',
  'quality-assurance-dashboard': 'Projects',
  'quality-assurance-review-reference-image': 'Review',
  'quality-assurance-review-selection': 'Review',
  'quality-assurance-reshoot-project': 'Reshoot',
  'admin-jobs': 'Jobs',
  'admin-settings': 'Settings',
  'admin-dashboard': 'Batch',
  'admin-reports': 'Reports',
  'not-found': 'Not Found',
  'unauthorized': 'Unauthorized',
  'projects': 'Projects',
  "super-admin": "Teams",
  'credits': 'Credits',
};

export default function getCurrentRouteName(): string {
  const route = useRoute();
  const currentPath = route?.name;
  return ReversedRoutes[currentPath] || 'Route not found';
}

// Example usage:
const currentRouteName = getCurrentRouteName();
console.log(currentRouteName); // Output: the corresponding value from ReversedRoutes or 'Route not found'
