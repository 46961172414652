import { useAuth0Store } from '@/stores/auth0Store'
import { Routes } from '@/router/Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (...roles: string[]) => {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        const { user, isAuthenticated } = useAuth0Store()

        if (isAuthenticated && roles.includes(user?.role ?? '')) {
            next()
        } else {
            next({
                name: Routes.Unauthorized,
            })
        }
    }
}
